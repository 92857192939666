<template>
  <div id="app">
    <Heads></Heads>
    <div id="app-body">
      <router-view></router-view>
    </div>
    <Footers></Footers>
  </div>
</template>

<script>
import Heads from "./components/TabBar.vue";
import Footers from "./components/Footers";
export default {
  components: {
    Heads,
    Footers,
  },
  name: "App",
  created() {
    console.log("2222", this.$cookies.get("TSESSIONID"));
    if (!this.$cookies.get("TSESSIONID")) {
      location.href = `${
        process.env.VUE_APP_LOGIN_URL
      }/?returnUrl=${encodeURIComponent(location.href)}`;
    }
    this.showBidAlert();
  },
  methods: {
    async showBidAlert() {
      const _this = this;
      const res = await this.$getAPI.getUserBidSuccessAlertStatus();
      if (res.code === "0") {
        this.$alert(
          "<div><p>尊敬的用户您好：</p><br><p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;系统监测到贵公司最近有项目中标记录，我们平台推出了中标贷业务，额度高，审核快，费率低。欢迎了解和申请业务</p></div>",
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "点击立即申请",
            callback: (action) => {
              if (action === 'confirm') {
                _this.closeUserBidSuccessAlert(res.data.url)
              }
            },
          }
        );
      }
    },
    async closeUserBidSuccessAlert(url) {
      await this.$getAPI.closeUserBidSuccessAlert()
      setTimeout(() => {
        window.open(url)
      }, 10);
    }
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  height: 100vh;
  &-body {
    position: relative;
    width: 1200px;
    margin: 0 auto;
  }
}
.el-pager,
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev {
  padding: 0 5px;
  margin: 0;
}
// .el-pager,
// .el-pagination.is-background .btn-next,
// .el-pagination.is-background .btn-next {
//   padding: 0 5px;
//   margin: 0;
// }
// .el-pagination.is-background .btn-next {
//   margin-right: 15px;
// }
// .el-pagination.is-background .btn-next,
// .el-pagination.is-background .btn-prev,
// .el-pagination.is-background .el-pager li {
//   border: 1px solid #dcdcdc;
//   box-shadow: 0px 2px 4px 0px rgba(0, 117, 230, 0.77);
//   border-radius: 4px;
//   background: #fff;
// }
</style>
