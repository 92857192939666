<template>
  <div>
    <div class="header">
      <!-- 头部 -->
      <div class="title">
        <div class="title_news_left">
          <span class="relax">让投标更轻松！</span>
          <p class="getcity">
            <img src="../assets/images/citys.png" alt="" />
            <span
              @click="selectCitys()"
              >{{ citysvals }}</span
            >
          </p>
        </div>
        <div class="title_news_right">
          <div class="LoginStyle" @click="login()">
            {{ companyName }}
            <ul class="config">
              <li @click.stop="goUcIndex">个人中心 ID:{{userid}}</li>
              <li @click.stop="exitLogin">退出登录</li>
            </ul>
          </div>
          <div @click="reglogin" v-show="!loginflag">免费注册</div>
          <div @click="kefus">客服中心</div>
          <div @click="fankui()">反馈</div>
        </div>
      </div>
      <!-- 反馈弹框 -->
      <div>
        <el-dialog title="反馈" width="30%" :close-on-click-modal="false" :visible.sync="dialogVisible">
          <div class="header-feedback">
            <div class="header-feedback-item">
              <div class="left">反馈类型：</div>
              <div class="right">
                <el-radio-group v-model="form.resource" @change="formError.resource=''">
                  <el-radio label="1">建议</el-radio>
                  <el-radio label="2">投诉</el-radio>
                </el-radio-group>
                <b>{{formError.resource}}</b>
              </div>
            </div>
            <div class="header-feedback-item">
              <div class="left">描述：</div>
              <div class="right">
                <el-input type="textarea" v-model="form.desc" @focus="formError.desc=''" @blur="form.desc === '' ? formError.desc = '请填写描述' : form.desc === '' "></el-input>
                <b>{{formError.desc}}</b>
              </div>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="feedbackSubmit"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>
      <div class="title_head">
        <div class="title_head_top">
          <div class="top_cont">
            <div class="cont_left" @click="goIndex">
              <div class="cont_left_logo">
                <img src="https://www.cyc-fund.com.cn/v2/images/home/logo.png" alt="" />
              </div>
            </div>
            <div class="cont_right">
              <div class="cont_right_tel">
                <span class="tel_logo"
                  ><img src="../assets/images/tel.png" alt=""
                /></span>
                <!-- 400 690 6108<br /> -->
                <span class="tel">13001143842</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="title_bottom">
        <div class="bottom_cont">
          <el-menu
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            background-color="#333333"
            text-color="#fff"
            active-text-color="#ffd04b"
          >
            <el-menu-item
              :index="item.path"
              v-for="(item, noindex) in noChildren"
              :key="noindex"
              @click="clickMenu(item)"
            >
              <!-- <img v-if="item.name === 'yearActive'" src="../assets/images/activeIcon.png" alt="" class="title-year-img"> -->
              <span slot="title">{{ item.label }}</span>
            </el-menu-item>
            <!-- <el-submenu index="13">
              <template slot="title" class="about">更多</template>
              <el-menu-item
                :index="item.path"
                v-for="(item, hasindex) in hasChildren"
                :key="hasindex"
                @click="clickMenu(item)"
              >
                {{ item.label }}
              </el-menu-item>
            </el-submenu> -->
          </el-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabBar",
  data() {
    return {
      userid: '',
      currentMenu: null,
      logIamge: require("../assets/images/logo.png"),
      asideMenu: [
        {
          path: process.env.VUE_APP_BASE_URL,
          name: "",
          label: "首页",
        },
        {
          path: `${process.env.VUE_APP_BASE_URL}/v2/html/channel.html`,
          name: "order",
          label: "投标保函",
        },
        {
          path: `${process.env.VUE_APP_BASE_URL}/bidTenderLoans/apply/bidLoans.do`,
          name: "BidLoan",
          label: "中标贷",
        },
        {
          path: `${process.env.VUE_APP_BASE_URL}/v2/static/zhfw.html`,
          name: "Query",
          label: "产品介绍",
        },
        {
          path: process.env.VUE_APP_BID_URL,
          name: "Pchome",
          label: "标讯信息",
        },
        {
          path: 'https://www.cyc-fund.com.cn/v2/html/study.html',
          name: "Shouce",
          label: "操作手册",
        },
        {
          path: `https://www.cyc-fund.com.cn/product/introduction/companyIntroductionLogin.do`,
          name: "Query",
          label: "平台介绍",
        },
        {
          path: "https://www.cyc-fund.com.cn/scoreCard/index.do",
          name: "New",
          label: "企业增信",
        },
        {
          path: "https://www.cyc-fund.com.cn/guaranteeQuery/guaranteeEntrance.do",
          name: "Query",
          label: "保函查询",
        },
        {
          path: "https://opt.cyc-fund.com.cn/Large/toNationwideLargeData.do",
          name: "Management",
          label: "数据大屏",
        },
        {
          path: 'https://news.cyc-fund.com.cn/',
          name: "Query",
          label: "新闻资讯",
        },
        {
          path: `${process.env.VUE_APP_BASE_URL}/v2/static/about.html`,
          name: "Query",
          label: "关于我们",
        },
      ],
      dialogVisible: false,
      form: {
        resource: "",
        desc: "",
      },
      formError: {
        resource: "",
        desc: "",
      },
      citysvals: "切换城市",
      companyName: "您好，请登录",
      loginflag: false,
      platformSrcList: {
        '331100' :'https://www.cyc-fund.com.cn/product/introduction/companyIntroductionLogin.do?areaCode=331100', //丽水
        '330200' :'https://www.cyc-fund.com.cn/product/introduction/companyIntroductionLogin.do?areaCode=330200', //宁波
        '530100' :'https://www.cyc-fund.com.cn/product/introduction/companyIntroductionLogin.do?areaCode=530100', //昆明
        '370100' :'https://www.cyc-fund.com.cn/product/introduction/companyIntroductionLogin.do?areaCode=370100' //济南
      }
    };
  },
  created() {
    this.citys();
    this.goPlatform()
    this.getUserID()
    const cin = this.$cookies.get("cin")
    if (decodeURIComponent(cin) != '丽水' && decodeURIComponent(cin) != '') {
      this.asideMenu = [
        {
          path: process.env.VUE_APP_BASE_URL,
          name: "",
          label: "首页",
        },
        {
          path: `${process.env.VUE_APP_BASE_URL}/v2/html/channel.html`,
          name: "order",
          label: "投标保函",
        },
        {
          path: `${process.env.VUE_APP_BASE_URL}/bidTenderLoans/apply/bidLoans.do`,
          name: "BidLoan",
          label: "中标贷",
        },
        {
          path: `${process.env.VUE_APP_BASE_URL}/v2/static/zhfw.html`,
          name: "Query",
          label: "产品介绍",
        },
        {
          path: process.env.VUE_APP_BID_URL,
          name: "Pchome",
          label: "标讯信息",
        },
        {
          path: 'https://www.cyc-fund.com.cn/v2/html/study.html',
          name: "Shouce",
          label: "操作手册",
        },
        {
          path: `https://www.cyc-fund.com.cn/product/introduction/companyIntroductionLogin.do`,
          name: "Query",
          label: "平台介绍",
        },
        {
          path: "https://www.cyc-fund.com.cn/scoreCard/index.do",
          name: "New",
          label: "企业增信",
        },
        {
          path: "https://www.cyc-fund.com.cn/guaranteeQuery/guaranteeEntrance.do",
          name: "Query",
          label: "保函查询",
        },
        {
          path: 'https://news.cyc-fund.com.cn/',
          name: "Query",
          label: "新闻资讯",
        },
        {
          path: `${process.env.VUE_APP_BASE_URL}/v2/static/about.html`,
          name: "Query",
          label: "关于我们",
        },
      ]
    }
  },
  computed: {
    // 没有子菜单和有子菜单
    noChildren() {
      return this.asideMenu.filter((item) => !item.children);
    },
    hasChildren() {
      return this.asideMenu.filter((item) => item.children);
    },
  },
  methods: {
    async getUserID() {
      const res = await this.$getAPI.getUserID()
      this.userid = res.data.result.resultData.orderList.id
    },
    goPlatform() {
      let code = this.$cookies.get('areaCode')
      if (code) {
        if (this.platformSrcList[code]) {
          let url = this.platformSrcList[code]
          let index = this.asideMenu.findIndex(v => v.label === '平台介绍')
          this.asideMenu[index].path = url
        }
      }
    },
    goUcIndex() {
      if (this.$route.path === '/') {
        window.location.reload()
      } else {
        this.$router.push('/')
      }
    },
    exitLogin() {
      this.$cookies.remove('TSESSIONID','/', process.env.VUE_APP_COOKIE_BASE);
      this.$cookies.remove('uscn', '/', process.env.VUE_APP_COOKIE_BASE);
      location.href = `${process.env.VUE_APP_LOGIN_URL}/systemCommonFS/logout.do`
    },
    citys() {
      if (this.$cookies.get("cin")) {
        this.citysvals = decodeURIComponent(this.$cookies.get("cin"));
      } else {
        this.citysvals = '丽水'
      }
      if (this.$cookies.get("uscn")) {
        this.loginflag = true;
        this.companyName = decodeURIComponent(this.$cookies.get("uscn"));
      }
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    clickMenu(item) {
      if (item.path == this.currentMenu) {
        return;
      } else {
        // this.$router.push({ path: item.path });

        if (item.path == "https://opt.cyc-fund.com.cn") {
          window.open("https://opt.cyc-fund.com.cn");
        } else {
          window.location.href = item.path;
        }
        // this.activeIndex2 = item.path;
        // console.log(this.activeIndex2);
        // console.log("***",item)
      }
      this.currentMenu = item.path;
    },
    login() {
      if (this.loginflag) {
        return;
      }
      window.location.href = `${process.env.VUE_APP_LOGIN_URL}/?returnUrl=${encodeURIComponent(location.href)}`;
    },
    fankui() {
      this.dialogVisible = true;
    },
    selectCitys() {
      window.location.href = `${process.env.VUE_APP_BASE_URL}/changecity.do`;
    },
    kefus() {
      window.open('https://www.cyc-fund.com.cn/userChat/goUserChat.do')
    },
    reglogin() {
      window.location.href = "";
    },
    goIndex() {
      window.open(process.env.VUE_APP_BASE_URL)
    },
    async feedbackSubmit() {
      let type = this.form.resource;
      let ctx = this.form.desc;
      if (type === '') {
        this.formError.resource = '请选择反馈类型'
        return;
      }
      if (ctx === '') {
        this.formError.desc = '请填写描述'
        return;
      }
      await this.$getAPI.feedback({
        feedBackType: type,
        inner: ctx
      })
      this.dialogVisible = false;
      this.form.resource = ''
      this.form.desc = ''
    }
  },
};
</script>

<style lang="less">
.header {
  width: 100%;
  .title {
    width: 1211px;
    height: 32px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: Hiragino Sans GB;
    font-weight: normal;
    color: #ccc;
    line-height: 32px;
  }

  .title_news_left span {
    float: left;
    cursor: pointer;
  }

  .title_news_left p {
    float: left;
    cursor: pointer;
  }

  .title_news_right div {
    float: left;
    margin-left: 20px;
    color: #000;
    cursor: pointer;
  }

  .title_bottom {
    width: 100%;
    height: 40px;
    background-color: #333333;
    z-index: 999;
  }
  .title_head {
    width: 100%;
    margin: 0 auto;
  }

  .title_head_top {
    width: 100%;
    margin: 0 auto;
    height: 70px;
    background-color: #fff;
  }
  .header-feedback {
    &-item {
      display: flex;
      padding: 10px 0;
      .left {
        text-align: right;
        width: 5em;
        margin-right: 10px;
      }
      .right {
        position: relative;
        b {
          color: #f00;
          font-size: 10px;
          position: absolute;
          left: 0;
          bottom: -12px;
          line-height: 10px;
        }
      }
    }
  }
}
</style>
<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  height: 100vh;
  /* background: #ededf3; */
}
.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0 !important;
  color: #fff !important;
  background-color: rgb(51, 51, 51) !important;
}
.el-menu--horizontal>.el-menu-item.is-active:hover {
  background-color: rgb(41, 41, 41) !important;
}
.el-carousel--horizontal {
  width: 100%;
}
.top_cont {
    width: 1211px;
    margin: 0 auto;
    height: 70px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
.cont_left_logo {
    width: 205px;
    height: 57px;
    text-align: center;
    cursor: pointer;
  }

  .cont_left_logo img {
    width: 100%;
  }

  .cont_right_tel {
    display: flex;
    justify-content: space-around;
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b1b5b1;
    line-height: 20px;
  }
.tel_logo {
    width: 34px;
    position: relative;
  }

  .tel_logo img {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .tel {
    margin-left: 10px;
  }
.bottom_cont {
    width: 1211px;
    margin: 0 auto;
    /* height: 59px !important; */
    /* overflow: hidden; */
  }
  .el-menu--horizontal {
    width: 100% !important;
    left: 0 !important;
    /* background-color: #f5f5f5 !important; */
  }


  .el-menu {
    /* background-color: #333333 !important; */
    /* height: 59px !important; */
    border: none !important;
  }
  .el-menu--horizontal > .el-menu-item {
    height: 40px;
    line-height: 40px;
  }

  .el-menu-item {
    /* background-color: #333 !important; */
  }
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: #333 !important;
  }

  .el-submenu__title {
    transition: none !important;
  }

  .el-menu--popup-bottom-start {
    width: 1212px !important;
    margin: 0 auto !important;
    display: flex;
    justify-content: flex-start;
    background-color: #f5f5f5 !important;
    border: none !important;
  }

  .el-menu--popup-bottom-start li {
    float: left !important;
    background-color: #f5f5f5 !important;
    color: #333333 !important;
    font-weight: 600;
  }
.bottom_cont >>> .el-submenu__title {
  background-color: #333 !important;
}
.bottom_cont >>> .el-menu--horizontal {
  display: flex;
  justify-content: space-between;
  border: none;
  /* height: 59px; */
  background-color: #333333 !important;
  width: 100% !important;
  border: none !important;
  left: 0 !important;
}
.el-menu--horizontal >>> .el-menu--popup-bottom-start {
  background-color: #f5f5f5 !important;
}
.el-menu--popup {
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
  .relax {
    color: #000;
    margin-left: 20px;
  }
  .getcity {
    color: #cd2e22;
  }
  .getcity img {
    width: 20px;
    height: 20px;
    margin-top: 6px;
    margin-left: 20px;
    float: left;
  }
  .LoginStyle {
    color: #cd2e22 !important;
    position: relative;
  }
  .LoginStyle:hover .config {
    display: block;
    background: #fff;
  }
  .LoginStyle .config {
    display: none;
    position: absolute;
    top: 32px;
    left: 50%;
    width: 10em;
    background: #fff;
    margin-left: -5em;
    color: rgba(0, 0, 0, 0.8);
    border: 1px solid #999;
    text-align: center;
    font-size: 18px;
    z-index: 1;
    /* margin-top: 5px; */
  }
  .LoginStyle .config li:nth-child(1) {
    border-bottom: 1px solid #999;
  }
  .LoginStyle .config li:hover {
    color: #f00;
    cursor: pointer;
  }
  .title-year-img {
    position: relative;
    bottom: -5px;
    height: 41px;
    width: auto;
  }
</style>